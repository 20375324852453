import $ from 'jquery'
import XhrPool from './XhrPool'
import * as Sentry from '@sentry/browser'

window.Sentry = Sentry;

// Show the error once the DOM is ready
window.showError = function showError(message) {
  if(document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', function() {
      showErrorWhenDomIsReady(message);
    });
  } else {
    showErrorWhenDomIsReady(message);
  }
}

// Show a mild (non-blocking) error banner
window.showErrorWhenDomIsReady = function(message) {
  // Show this default message if no message is provided
  const defaultMessage = "We're experiencing delays or errors on this page. If it's working for you, please proceed. If not, please refresh the page and try again. Everything should be back to normal soon. You can always chat with us if you have any questions.";
  
  // Check if the error message div already exists
  let errorMessageDiv = document.getElementById('error-message');

  // If it doesn't exist, create it
  if (!errorMessageDiv) {
    errorMessageDiv = document.createElement('div');
    errorMessageDiv.id = 'error-message';
    errorMessageDiv.className = 'error-message';
    errorMessageDiv.innerHTML = `
      <div class="error-message__icon"></div>
      <h5>We're experiencing some <br>issues at the moment</h5>
      <p>
        ${message || defaultMessage}
      </p> 
      <button>Okay, got it</button>
    `;

    // Append and show the error message div
    document.body.appendChild(errorMessageDiv);
    errorMessageDiv.style.display = 'block';

    // Listen to click events to remove the error message
    document.querySelector('#error-message button').addEventListener('click', function() {
      document.body.removeChild( document.querySelector('#error-message') );
    })
  }
}

// Show a message when there are uncaught exceptions
window.onerror = function(msg, url, line, col, error) {
  const errorMessage = (error) ? error.message : '';
  if (msg != 'Script error.' && 
      errorMessage != 'Script error.' && 
      msg != 'SecurityError: The operation is insecure.' && 
      errorMessage != 'SecurityError: The operation is insecure.')
  {
    showError();
  }
  // console.log(msg, url, line, col, error, error.message);
}

// Create a pool that keeps track of all ajax requests
const xhrPool = new XhrPool()

// Abort all requests when the page is unloaded to avoid error messages
window.onbeforeunload = function(e) {
  xhrPool.abortAll()
}

// Log ajax errors and display an error message
// Use { global: false } if you don't want this default behavior in an ajax request http://api.jquery.com/ajaxError/
$(document).ajaxError(function( event, request, settings, error ) {
  // Ignore ajax error if it's an aborted request
  // We also checked request.statusText === 'abort' earlier but it wasn't 100% reliable in all browsers. Some aborted requests (e.g. when moving to a different page) did not have a statusText and it resulted in error messages for the user for no good reason.
  // As we no longer check statusText, offline errors will also end up here
  if( request.status === 0 ) {
    // Nothing to worry about here

  // Log other errors
  } else {
    // Log error
    if(request.responseText.includes('Your session expired')){
      // Reload window so Rails sends user to Sign-In Screen
      window.location.reload()
    } else if( request.responseText == 'upstream connect error or disconnect/reset before headers' ) {
      // Log this kind of exception with an uniform message so they are grouped. Indicating the unique url fills Sentry up with a lot of unique exceptions.
      window.Sentry.captureException('Ajax error. Status: ' + request.status + '. Status text: ' + request.statusText + '. Response text: ' + request.responseText)
    } else {
      window.Sentry.captureException('Ajax error. Url: ' + settings.url + '. Status: ' + request.status + '. Status text: ' + request.statusText + '. Response text: ' + request.responseText)
    }

    // Display error message
    window.showError('Something went wrong. Please refresh the page and try again.')
  }
})

// Uncomment to test:
// throw new Error('Test error.')
